import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import { useStore } from 'vuex'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'TestLogin',
    redirect: '/pages/login',
    component: DefaultLayout,
  },
  {
    path: '/dashboard',
    name: 'Start',
    meta: {
      requiresAuth: true,
    },
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/start',
        name: 'Start',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Start.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Start.vue'),
      },
      {
        path: '/dev/working',
        name: 'W trakcie tworzenia',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/Working.vue'),
      },
      {
        path: '/jmp/service',
        name: 'Protokół serwisowy',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/service.vue'),
      },
      {
        path: '/jmp/users',
        name: 'Zarządzanie dostępem użytkowników',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/users.vue'),
      },
      {
        path: '/jmp/changePassword',
        name: 'Zmiana hasła użytkownika',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/changePass.vue'),
      },
      {
        path: '/jmp/parkMaszynowyAll',
        name: 'Wszystkie pojazdy (wyszukiwarka)',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyAll.vue'),
      },
      {
        path: '/jmp/parkMaszynowyKlienci',
        name: 'Filtrowanie klientów',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyKlienci.vue'),
      },
      {
        path: '/jmp/parkMaszynowyL',
        name: 'Lista pojazdów sprzedanych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyL.vue'),
      },
      {
        path: '/jmp/parkMaszynowyW',
        name: 'Lista pojazdów wypożyczonych/podstawionych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowyW.vue'),
      },
      {
        path: '/jmp/parkMaszynowy',
        name: 'sprzęt na stanie',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/parkMaszynowy.vue'),
      },
      {
        path: '/jmp/zleceniaSerwisowe',
        name: 'zlecenia serwisowe',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/zleceniaSerwisowe.vue'),
      },
      {
        path: '/jmp/wNaprawie',
        name: 'sprzęt w naprawie',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/wNaprawie.vue'),
      },
      {
        path: '',
        name: 'zlecenia handlowe',
        children: [
          {
            path: '/jmp/zleceniaHandloweSprzedaz',
            name: 'sprzedaż',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaHandloweSprzedaz.vue'),
          },
          {
            path: '/jmp/zleceniaHandloweWynajem',
            name: 'wynajem',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaHandloweWynajem.vue'),
          },
          {
            path: '/jmp/zleceniaHandloweUsluga',
            name: 'usłsuga',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaHandloweUsluga.vue'),
          },
        ]
      },
      {
        path: '',
        name: 'zlecenia napraw',
        children: [
          {
            path: '/jmp/zleceniaWarsztatowe',
            name: 'zgłoszenia',
            meta: {
              requiresAuth: true,
            },
            component: () =>
              import('@/views/jmp/zleceniaWarsztatowe.vue'),
          },
          {
            path: 'serwisMobilny/:id',
            name: 'serwis mobilny',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zlecenieMobilne.vue'),
            children: [],
          },
          {
            path: 'serwisStacjonarny/:id',
            name: 'zlecenie serwisowe',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zlecenieStacjonarne.vue'),
            children: [],
          },
          {
            path: '/jmp/zleceniaNaprawMobilne',
            name: 'zlecenia mobilne',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawMobilne.vue'),
            children: [],
          },
          {
            path: '/jmp/zleceniaNaprawWarsztatowe',
            name: 'zlecenia warsztatowe',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawWarsztatowe.vue'),
            children: [],
          },
          {
            path: '/jmp/zleceniaNaprawWynajem',
            name: 'zlecenia wynajem',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/zleceniaNaprawWynajem.vue'),
            children: [],
          }
        ]
      },
      {
        path: '/jmp/zleceniaCzesci',
        name: 'baza części do zleceń',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/zleceniaCzesci.vue'),
      },
      {
        path: '/jmp/osprzetDoMaszyn',
        name: 'osprzęt do maszyn',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/osprzetDoMaszyn.vue'),
      },
      {
        path: '/jmp/raportPrzeglady',
        name: 'raport przeglądów serwisowych',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportPrzeglady.vue'),
      },
      {
        path: '/jmp/raportBadania',
        name: 'raport badań',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportBadania.vue'),
      },
      {
        path: '/jmp/raportWynajem',
        name: 'raport_wynajem',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportWynajem.vue'),
      },
      {
        path: '/jmp/araportWynajemAll',
        name: 'raport_wszystkich_wynajmów',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/jmp/raportWynajemAll.vue'),
      },
      {
        path: '/jmp/listaCzynnosci',
        name: 'listy kontrolne',
        children: [
          {
            path: '',
            name: 'listy kontrolne',
            meta: {
              requiresAuth: true,
            },
            component: () =>
          import('@/views/jmp/pytania.vue'),
          },
          {
            path: 'CzynnoscEdycja/:id',
            name: 'edycja listy kontrolnej',
            meta: {
              requiresAuth: true,
              active: true
            },
            component: () =>
              import('@/views/jmp/pytaniaPozycje.vue'),
            children: [],
          }
        ]
      },
      {
        path: '/theme',
        name: 'Theme',
        redirect: '/theme/typography',
      },
      {
        path: '/theme/colors',
        name: 'Colors',
        component: () => import('@/views/theme/Colors.vue'),
      },
      {
        path: '/theme/typography',
        name: 'Typography',
        component: () => import('@/views/theme/Typography.vue'),
      },
      {
        path: '/base',
        name: 'Base',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/base/breadcrumbs',
        children: [
          {
            path: '/base/accordion',
            name: 'Accordion',
            component: () => import('@/views/base/Accordion.vue'),
          },
          {
            path: '/base/breadcrumbs',
            name: 'Breadcrumbs',
            component: () => import('@/views/base/Breadcrumbs.vue'),
          },
          {
            path: '/base/cards',
            name: 'Cards',
            component: () => import('@/views/base/Cards.vue'),
          },
          {
            path: '/base/carousels',
            name: 'Carousels',
            component: () => import('@/views/base/Carousels.vue'),
          },
          {
            path: '/base/collapses',
            name: 'Collapses',
            component: () => import('@/views/base/Collapses.vue'),
          },
          {
            path: '/base/list-groups',
            name: 'List Groups',
            component: () => import('@/views/base/ListGroups.vue'),
          },
          {
            path: '/base/navs',
            name: 'Navs',
            component: () => import('@/views/base/Navs.vue'),
          },
          {
            path: '/base/paginations',
            name: 'Paginations',
            component: () => import('@/views/base/Paginations.vue'),
          },
          {
            path: '/base/placeholders',
            name: 'Placeholders',
            component: () => import('@/views/base/Placeholders.vue'),
          },
          {
            path: '/base/popovers',
            name: 'Popovers',
            component: () => import('@/views/base/Popovers.vue'),
          },
          {
            path: '/base/progress',
            name: 'Progress',
            component: () => import('@/views/base/Progress.vue'),
          },
          {
            path: '/base/spinners',
            name: 'Spinners',
            component: () => import('@/views/base/Spinners.vue'),
          },
          {
            path: '/base/tables',
            name: 'Tables',
            component: () => import('@/views/base/Tables.vue'),
          },
          {
            path: '/base/tooltips',
            name: 'Tooltips',
            component: () => import('@/views/base/Tooltips.vue'),
          },
        ],
      },
      {
        path: '/buttons',
        name: 'Buttons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/buttons/standard-buttons',
        children: [
          {
            path: '/buttons/standard-buttons',
            name: 'Buttons',
            component: () => import('@/views/buttons/Buttons.vue'),
          },
          {
            path: '/buttons/dropdowns',
            name: 'Dropdowns',
            component: () => import('@/views/buttons/Dropdowns.vue'),
          },
          {
            path: '/buttons/button-groups',
            name: 'Button Groups',
            component: () => import('@/views/buttons/ButtonGroups.vue'),
          },
        ],
      },
      {
        path: '/forms',
        name: 'Forms',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/forms/form-control',
        children: [
          {
            path: '/forms/form-control',
            name: 'Form Control',
            component: () => import('@/views/forms/FormControl.vue'),
          },
          {
            path: '/forms/select',
            name: 'Select',
            component: () => import('@/views/forms/Select.vue'),
          },
          {
            path: '/forms/checks-radios',
            name: 'Checks & Radios',
            component: () => import('@/views/forms/ChecksRadios.vue'),
          },
          {
            path: '/forms/range',
            name: 'Range',
            component: () => import('@/views/forms/Range.vue'),
          },
          {
            path: '/forms/input-group',
            name: 'Input Group',
            component: () => import('@/views/forms/InputGroup.vue'),
          },
          {
            path: '/forms/floating-labels',
            name: 'Floating Labels',
            component: () => import('@/views/forms/FloatingLabels.vue'),
          },
          {
            path: '/forms/layout',
            name: 'Layout',
            component: () => import('@/views/forms/Layout.vue'),
          },
          {
            path: '/forms/validation',
            name: 'Validation',
            component: () => import('@/views/forms/Validation.vue'),
          },
        ],
      },
      {
        path: '/charts',
        name: 'Charts',
        component: () => import('@/views/charts/Charts.vue'),
      },
      {
        path: '/icons',
        name: 'Icons',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/icons/coreui-icons',
        children: [
          {
            path: '/icons/coreui-icons',
            name: 'CoreUI Icons',
            component: () => import('@/views/icons/CoreUIIcons.vue'),
          },
          {
            path: '/icons/brands',
            name: 'Brands',
            component: () => import('@/views/icons/Brands.vue'),
          },
          {
            path: '/icons/flags',
            name: 'Flags',
            component: () => import('@/views/icons/Flags.vue'),
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/notifications/alerts',
        children: [
          {
            path: '/notifications/alerts',
            name: 'Alerts',
            component: () => import('@/views/notifications/Alerts.vue'),
          },
          {
            path: '/notifications/badges',
            name: 'Badges',
            component: () => import('@/views/notifications/Badges.vue'),
          },
          {
            path: '/notifications/modals',
            name: 'Modals',
            component: () => import('@/views/notifications/Modals.vue'),
          },
        ],
      },
      {
        path: '/widgets',
        name: 'Widgets',
        component: () => import('@/views/widgets/Widgets.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },

    ],

  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  let store = useStore()
  if (to.meta.requiresAuth && store.state.jwt === '') {
    next("/pages/login");
  } else {
    next();
  }
});

export default router
